import request from '@/utils/request'
const baseURL = '/baseapi'
const visitrecordApi = {
  VisitrecordkList: '/admin/visitrecord/list',
  VisitRecordInfo: '/admin/visitrecord/visitRecordInfo',
  VisitRecordById: '/admin/visitrecord/visitRecordById',
  SaveVisitRecord: '/admin/visitrecord/saveVisitRecord',
  DelVisitRecord: '/admin/visitrecord/delVisitRecord'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */

export function visitrecordkList (parameter) {
  return request({
    baseURL: baseURL,
    url: visitrecordApi.VisitrecordkList,
    method: 'post',
    data: parameter
  })
}

export function visitRecordInfo (parameter) {
  return request({
    baseURL: baseURL,
    url: visitrecordApi.VisitRecordInfo,
    method: 'post',
    data: parameter
  })
}

export function visitRecordById (parameter) {
  return request({
    baseURL: baseURL,
    url: visitrecordApi.VisitRecordById,
    method: 'post',
    data: parameter
  })
}

export function saveVisitRecord (parameter) {
  return request({
    baseURL: baseURL,
    url: visitrecordApi.SaveVisitRecord,
    method: 'post',
    data: parameter
  })
}

export function delVisitRecord (parameter) {
  return request({
    baseURL: baseURL,
    url: visitrecordApi.DelVisitRecord,
    method: 'post',
    data: parameter
  })
}
